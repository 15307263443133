import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Label, UncontrolledDropdown, Button,
    DropdownMenu, DropdownToggle
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import { history } from '../../history';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from './Header.run';
import PubSub from 'pubsub-js';
import Axios from 'axios';
import { URL, URL_Notifications } from '../Services/Notifications/notificationsService';
import { EnumNotifications } from '../Utils/Enuns/TypeNotifications';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { TypeCompany } from '../Utils/Enuns/TypeCompany';
import { differenceInDays, compareDesc } from 'date-fns'

class Header extends Component {
    app_token = JSON.parse(localStorage.getItem('app-token')) ? JSON.parse(localStorage.getItem('app-token')) : '';
    clinic = JSON.parse(localStorage.getItem('ClinicData')) ? JSON.parse(localStorage.getItem('ClinicData')) : '';

    newConnection = new HubConnectionBuilder()
        .withUrl(`${URL.replace(/\/api([^/api]*)$/, '' + '$1')}/hubs/chat`, {
            accessTokenFactory: () => this.app_token.token,
            transport: HttpTransportType.LongPolling,
            transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
        .build();


    constructor(props) {
        super(props)
        this.calendarRef = React.createRef();
        this.state = {
            file: '',
            notifications: [{}],
            results: [],
            contNotifications: 0,
            connection: null,
            idMessage: 0,
            loading: false,
            loadMoreEnabled: true,
            page: 1,
            nameClinic: '',
            loadingButtonsNotifications: false,
            idCorrentNotify: 0,
            Periodicity: 'undefined',
            testDays: 0
        }
    }

    componentDidMount = async () => {
        HeaderRun();
        if (this.clinic != '') {
            await this.consultPlanClinic()
            await this.newHubConnectionBuilder()
        }
    }

    consultPlanClinic = async () => {
        let plan = JSON.parse(localStorage.getItem('Plan'));

        if (plan != null) {
            const result = differenceInDays(
                Date.parse(plan.effectiveDate), new Date()
            )
            const expiretTest = compareDesc(Date.parse(plan.effectiveDate), new Date())
            this.setState({ testDays: result, expiretTest: expiretTest, Periodicity: plan.periodicity })
        }
    }

    newHubConnectionBuilder = async () => {
        let idMessageHub = "";
        if (this.newConnection) {
            await this.newConnection.start()
                .then(result => {
                    this.newConnection.invoke('GetConnectionId', this.clinic.id)
                        .then(connectionId => {
                            // Send the connectionId to controller
                        })
                        .catch(e => console.log('Connection failed at scheduling: ', e));
                })
            this.newConnection.onreconnected(resp => {
                console.log("Conexão de notificação restabelecida com sucesso!")
                this.newConnection.invoke('GetConnectionId', this.clinic.id)
            })

            this.newConnection.on('ReceiveMessage', message => {
                idMessageHub = message.idMessage;
                this.onNotificationHub(idMessageHub);
            })
            this.newConnection.on('NotifyScheduleChange', message => {
                this.refreshCalendarComponent();
            })
            this.newConnection.on('NotifyEventNfseChange', message => {
                this.refresh();
            })
        }
    }
    refresh = () => {
        window.location.reload()
    }
    refreshCalendarComponent = () => {
        const myParam = window.location.pathname.slice(1)
        if (myParam == 'calendar')
            window.location.reload()
    }

    onNotificationHub(idMessageHub) {
        const { contNotifications, idMessage } = this.state
        if (idMessageHub != idMessage) {
            let contN = contNotifications;
            contN++;
            this.setState({
                contNotifications: contN,
                idMessage: idMessageHub
            })
        }
    }

    // numberOfUnreadNotifications = async () => {
    //     const idDoctor = localStorage.getItem('idDoctor')
    //     await Axios.get(`${URL_Notifications}/GetNumberOfUnreadNotifications`, {
    //         params: {
    //             idDoctor: idDoctor != null ? idDoctor : 0,
    //         }
    //     }).then(resp => {
    //         const { data } = resp
    //         this.setState({
    //             contNotifications: data
    //         })
    //     })
    // }

    consultNotifications = async () => {
        const idDoctor = localStorage.getItem('idDoctor')

        await Axios.get(URL_Notifications, {
            params: {
                idDoctor: idDoctor != null ? idDoctor : 0,
            }
        }).then(resp => {
            const { data } = resp
            this.setState({
                results: data.results,
                page: 2
            })
        })
        // this.numberOfUnreadNotifications();
    }

    loadMore = async () => {
        const idDoctor = localStorage.getItem('idDoctor')
        this.setState({ loading: true })
        const { page, results } = this.state
        await Axios.get(URL_Notifications, {
            params: {
                idDoctor: idDoctor != null ? idDoctor : 0,
                pageNumber: page,
            }
        }).then(resp => {
            const { data } = resp
            if (data.results.length > 0) {
                results.push.apply(results, data.results)
                this.setState({
                    results: results,
                    page: page + 1,
                    loading: false
                })
            } else {
                this.setState({ loadMoreEnabled: false })
            }
        })
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    CloseSistema = e => {
        localStorage.clear();
        sessionStorage.clear();
        this.newConnection.onclose((error) => {
            console.error(`Something went wrong: ${error}`);
        });
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    clickNotification = async (e, n) => {
        e.preventDefault()
        if (n.reading == false) {
            n.reading = true
            await Axios.put(URL_Notifications, n).then(resp => {
                this.consultNotifications();
            });
        }
        history.push(`/calendarDateNow/${n.date}`)
    }

    clickNotificationPreScheduling = async (e, n) => {
        e.preventDefault()
        if (n.reading == false) {
            n.reading = true
            await Axios.put(URL_Notifications, n).then(resp => {
                this.consultNotifications();
            });
        }
        history.push('/prescheduling')
    }

    componentWillMount() {
        PubSub.subscribe('nameClinic', (topic, clinic) => {
            this.setState({ nameClinic: clinic });
        })
    }

    deleteNotification = async (id) => {
        this.setState({ loadingButtonsNotifications: true, idCorrentNotify: id })
        await Axios.delete(`${URL_Notifications}/${id}`).then(resp => {
            const { data } = resp
            if (data)
                this.consultNotifications()
        })
        this.setState({ loadingButtonsNotifications: false })
    }

    confirmNotifications = async (n) => {
        this.setState({ loadingButtonsNotifications: true, idCorrentNotify: n.id })
        if (n.reading == false) {
            n.reading = true
            await Axios.put(URL_Notifications, n).then(resp => {
                this.consultNotifications();
            });
        }
        this.setState({ loadingButtonsNotifications: false })
    }

    render() {
        const { results, contNotifications, loading, expiretTest, loadMoreEnabled, Periodicity,
            loadingButtonsNotifications, nameClinic, idCorrentNotify, testDays } = this.state
        return (

            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand">
                            <div className="brand-logo">
                                <img className="img-fluid w-25 m-auto" src="img/IGICoficialbranco.png" alt="App Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/IGICoficialbranco.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        { /* START User avatar toggle */}
                        <li className="nav-item d-none d-md-block">
                            <a className="nav-link" onClick={this.toggleUserblock}>
                                <em className="icon-user"></em>
                            </a>
                        </li>
                        { /* END User avatar toggle */}
                        { /* START lock screen */}
                        <li className="nav-item d-none d-md-block">
                            <Link to="lock" title="travar a tela" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <Label className='mt-3 text-light'>
                                <em className="fas fa-store-alt"></em>{'  '}
                                {nameClinic ??
                                    this.app_token != '' ?
                                    this.app_token.clinic[0].corporateName : ''
                                }
                                {this.app_token != '' && this.app_token.idDoctor != undefined ?
                                    ' - ' + this.app_token.name : ''
                                }
                            </Label>
                            {/* <SelectedClinic/> */}
                        </li>
                        { /* END lock screen */}
                    </ul>
                    {this.clinic.typeCompany == TypeCompany.company
                        && !Periodicity ?

                        <ul className='navbar-nav mr-auto flex-row'>
                            <br />
                            <div className='text-white'>
                                {
                                    expiretTest == -1 ?
                                        <b className='font-weight-bold text-white'><b className='font-weight-bold text-white h4'>Restam {testDays} dias</b> de teste grátis</b>
                                        : <b className='font-weight-bold text-white h3'>Teste expirado!</b>
                                }
                                {'  '}<Button
                                    color="success"
                                    onClick={e => history.push(`/paymentwizard/${this.clinic.identifier}`)}
                                > Ative sua conta agora</Button></div>
                        </ul> : null}
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        { /* Search icon */}
                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>
                        { /* Fullscreen (only desktops) */}
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link" />
                        </li>
                        { /* START Alert menu */}
                        <UncontrolledDropdown
                            nav
                            inNavbar
                        >
                            <DropdownToggle nav className="dropdown-toggle-nocaret"
                                onClick={e => this.consultNotifications()}
                            >
                                <em className="icon-bell"></em>
                                <span className="badge badge-danger">{contNotifications == 0 ? ''
                                    : contNotifications}</span>
                            </DropdownToggle>
                            { /* START Dropdown menu */}
                            <DropdownMenu
                                className={'dropdown-menu-right pre-scrollable'}
                            >
                                {results.map(n => (
                                    n.type == EnumNotifications.preScheduling ?
                                        <div key={n.id} className={n.reading ? 'list-group-item list-group-item-action'
                                            : "list-group-item list-group-item-action list-group-item-primary"}
                                        >
                                            {/*notification*/}
                                            <div className="media">
                                                <div className="media-body text-truncate"
                                                    onClick={e => this.clickNotificationPreScheduling(e, n)}>
                                                    <div className="media-body">
                                                        <p className="m-0"><em className="fa fa-tasks fa-2x text-primary"></em>
                                                            {' ' + n.title}</p>
                                                        <p className="m-0 text-muted text-sm">{n.message}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-auto">
                                                    <div className='row'>
                                                        <div className='col'
                                                        >
                                                            {loadingButtonsNotifications == true && idCorrentNotify == n.id ?
                                                                <i className="fas fa-spinner fa-spin" /> :
                                                                <em className="fas fa-trash-alt text-danger"
                                                                    title="Excluir"
                                                                    onClick={e => this.deleteNotification(n.id)}
                                                                >
                                                                </em>}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className='row'>
                                                        <div className='col'>
                                                            {loadingButtonsNotifications == true && idCorrentNotify == n.id ?
                                                                <i className="fas fa-spinner fa-spin" /> :
                                                                <em className="fas fa-check-double text-success"
                                                                    title="Confirmar"
                                                                    onClick={e => this.confirmNotifications(n)}
                                                                ></em>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div key={n.id} className={n.reading ? 'list-group-item list-group-item-action'
                                            : "list-group-item list-group-item-action list-group-item-primary"}
                                        >
                                            {/*Pre notification*/}
                                            <div className="media">
                                                <div className="media-body text-truncate"
                                                    onClick={e => this.clickNotification(e, n)}
                                                >
                                                    <div className="media-body">
                                                        <p className="m-0">
                                                            <em className="fa fa-envelope-open fa-2x text-primary"></em>
                                                            {' ' + n.title}</p>
                                                        <p className="m-0 text-muted text-sm">{n.message}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-auto">
                                                    <div className='row'>
                                                        <div className='col'
                                                        >
                                                            {loadingButtonsNotifications == true && idCorrentNotify == n.id ?
                                                                <i className="fas fa-spinner fa-spin" /> :
                                                                <em className="fas fa-trash-alt text-danger"
                                                                    title="Excluir"
                                                                    onClick={e => this.deleteNotification(n.id)}
                                                                >
                                                                </em>}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className='row'>
                                                        <div className='col'>
                                                            {loadingButtonsNotifications == true && idCorrentNotify == n.id ?
                                                                <i className="fas fa-spinner fa-spin" /> :
                                                                <em className="fas fa-check-double text-success"
                                                                    title="Confirmar"
                                                                    onClick={e => this.confirmNotifications(n)}
                                                                ></em>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))}
                                {loadMoreEnabled ? (
                                    <Button
                                        className="btn-block"
                                        color="primary"
                                        onClick={e => this.loadMore()}
                                        disabled={loading}
                                    >{loading && (
                                        <i
                                            class="fas fa-spinner fa-spin"
                                        />
                                    )}
                                        {loading && <span> Carregando...</span>}
                                        {!loading && <span> Carregar Mais</span>}
                                    </Button>
                                ) : null}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        { /* START Offsidebar button */}
                        <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-notebook"></em>
                            </a>
                        </li>
                        { /* END Offsidebar menu */}

                        <li className="nav-item">
                            <a className="nav-link"
                                title="Sair do Sistema"
                                href=""
                                onClick={this.CloseSistema}>
                                <em className="icon-logout"></em>
                            </a>
                        </li>
                    </ul>
                    { /* END Right Navbar */}

                    { /* START Search form */}
                    <form className="navbar-form" role="search" action="search.html">
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="Type and hit enter ..." />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }
}

export function SelectedClinic(e) {
    // const items = localStorage.getItem('idClinic')
    PubSub.publish('nameClinic', e)
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

