import { EnumCodeScreen } from "./components/Utils/Enuns/CodeScreen";
const Menu = [
    {
        heading: 'Menu de Navegação',
        translate: 'sidebar.heading.HEADER'
    },


    {
        name: 'Agenda',
        icon: 'fa fa-calendar',
        translate: 'sidebar.nav.AGENDA',
        submenu: [{
            codeScreen: EnumCodeScreen.Agenda,
            viewPermission: false,
            name: 'Minha Agenda',
            path: '/calendar'
        },
        // {
        //     name: 'Lista de Espera',
        //     path: '/prescheduling',
        //     codeScreen: EnumCodeScreen.PreAgendamento,
        //     viewPermission: false,
        // },
        {
            name: 'Lembretes',
            path: '/dashboardv1',
            codeScreen: EnumCodeScreen.Dashboard,
            viewPermission: false
        }

        ]
    },

    {
        name: 'Vendas',
        icon: 'fa fa-shopping-bag',
        translate: 'sidebar.nav.VENDAS',
        submenu: [
            {
                name: 'Vender Pacotes',
                path: '/sellpackage',
                codeScreen: EnumCodeScreen.VendaPacote,
                viewPermission: false,
            },
            {
                codeScreen: EnumCodeScreen.VendaProduto,
                viewPermission: false,
                name: 'Vender Produtos',
                path: '/sellproduct'
            },

        ]
    },

    {
        name: 'Financeiro',
        icon: 'fa fa-chart-line',
        translate: 'sidebar.nav.FINANCEIRO',
        submenu: [
            {
                name: 'Finanças',
                path: '/financialoperation',
                codeScreen: EnumCodeScreen.Financeiro,
                viewPermission: false,
            },

            {
                name: 'Contas Financeiras',
                path: '/financialaccountregister',
                codeScreen: EnumCodeScreen.CadastroContaFinanceira,
                viewPermission: false,
            },
            {
                name: 'Forma de Pagamento',
                path: '/formofpaymentregistration',
                codeScreen: EnumCodeScreen.CadastroFormaPagamento,
                viewPermission: false,
            },
        ]
    },

    {
        name: 'Estabelecimento',
        icon: 'fa fa-briefcase',
        translate: 'sidebar.nav.ESTABELECIMENTO',
        submenu: [{
            name: 'Dados Cadastrais',
            path: '/officeregister',
            codeScreen: EnumCodeScreen.CadastroConsultorio,
            viewPermission: false,
        },

        {
            name: 'Usuários',
            path: '/userregister',
            codeScreen: EnumCodeScreen.CadastroUsuarios,
            viewPermission: false,
        },

        {
            name: 'Perfil',
            path: '/profilesregister',
            codeScreen: EnumCodeScreen.CadastroPerfil,
            viewPermission: false,
        },

        {
            name: 'Clientes',
            path: '/ConsultCostumers',
            codeScreen: EnumCodeScreen.CadastroCliente,
            viewPermission: false,
        },

        {
            name: 'Profissionais',
            path: '/doctorregister',
            codeScreen: EnumCodeScreen.CadastroProfissionalSaude,
            viewPermission: false,
        },

        {
            name: 'Centro de Custo',
            path: '/costcenterregister',
            codeScreen: EnumCodeScreen.CadastroCentroCustos,
            viewPermission: false,
        },

        {
            name: 'Fornecedores',
            path: '/providerregister',
            codeScreen: EnumCodeScreen.CadastroFornecedor,
            viewPermission: false,
        },

        {
            name: 'Estoque',
            path: '/stockoperation',
            codeScreen: EnumCodeScreen.Estoque,
            viewPermission: false,
        },

        {
            name: 'Produtos',
            path: '/productregister',
            codeScreen: EnumCodeScreen.CadastroProduto,
            viewPermission: false,
        },

        {
            name: 'Pacotes',
            path: '/packageregister',
            codeScreen: EnumCodeScreen.PacoteDeProcedimentos,
            viewPermission: false,
        },

        {
            name: 'Medicamentos',
            path: '/medicamentsregister',
            codeScreen: EnumCodeScreen.CadastroMedicamentos,
            viewPermission: false,
        },

        

        {
            name: 'Procedimentos',
            path: '/procedureregister',
            codeScreen: EnumCodeScreen.CadastroProcedimento,
            viewPermission: false,
        },

        {
            name: 'Procedimentos Hof',
            path: '/procedurehofregister',
            codeScreen: EnumCodeScreen.CadastroProcedimentoHof,
            viewPermission: false,
        },

        {
            name: 'Grupo Procedimentos',
            path: '/groupprocedureregister',
            codeScreen: EnumCodeScreen.CadastroGrupodeProcedimento,
            viewPermission: false,
        },

        {
            name: 'Salas',
            path: '/roomsregister',
            codeScreen: EnumCodeScreen.CadastroSalas,
            viewPermission: false,
        },

        {
            name: 'Avaliações',
            path: '/ratingsregister',
            codeScreen: EnumCodeScreen.CadastroAvaliacoes,
            viewPermission: false,
        },

        {
            name: 'Anamnese',
            path: '/anamnesisregistration',
            codeScreen: EnumCodeScreen.CadastroAnamnese,
            viewPermission: false,
        }
            // {
            //     name: 'Conta Financeira',
            //     path: '/financialaccountregister',
            //     codeScreen: EnumCodeScreen.CadastroContaFinanceira,
            //     viewPermission: false,
            // },
            // {
            //     name: 'Promoção',
            //     path: '/promotionregister',
            //     codeScreen: EnumCodeScreen.CadastroPromocao,
            //     viewPermission: false,
            // },
            // {
            //     name: 'Configurações',
            //     path: '/configurationsregister',
            //     codeScreen: EnumCodeScreen.CadastroConfiguracoes,
            //     viewPermission: false,
            // },

        ]
    },


    {
        name: 'Nota Fiscal',
        icon: 'fas fa-receipt',
        translate: 'sidebar.nav.NOTAFISCAL',
        submenu: [{
            name: 'Emissão Nfse',
            path: '/nfse',
            codeScreen: EnumCodeScreen.Nfse,
            viewPermission: false,
        },
        {
            name: 'Dados Nfse',
            path: '/DadosNfse',
            codeScreen: EnumCodeScreen.DadosNfse,
            viewPermission: false,
        },

        ]
    },

    {
        name: 'Recibo',
        icon: 'fas fa-receipt',
        translate: 'sidebar.nav.RECEIPT',
        submenu: [{
            codeScreen: EnumCodeScreen.Recibo,
            viewPermission: false,
            name: 'Emissão Recibo',
            path: '/receiptIssuance'
        },
        ]
    },

    {
        name: 'Atestado',
        icon: 'fas fa-file-alt',
        translate: 'sidebar.nav.QUEUE',
        submenu: [
            {
                name: 'Emissão Atestado',
                path: '/attestedemission',
                codeScreen: EnumCodeScreen.EmissaoAtestado,
                viewPermission: false,
            },
            {
                name: 'Modelo Atestado',
                path: '/attestedmodel',
                codeScreen: EnumCodeScreen.Atestado,
                viewPermission: false,
            },
        ]
    },

    {
        name: 'Receituário',
        icon: 'fas fa-file-alt',
        translate: 'sidebar.nav.RECEITUARIO',

        submenu: [
            {
                name: 'Emissão Receituário',
                path: '/prescriptionsregister',
                codeScreen: EnumCodeScreen.Receituario,
                viewPermission: false,
            }
        ]
    },

    {
        name: 'Tratamento',
        icon: 'fas fa-procedures',
        translate: 'sidebar.nav.TREATMENT',
        submenu: [{
            name: 'Consultas',
            path: '/SearchTreatment',
            codeScreen: EnumCodeScreen.ConsultasTratamento,
            viewPermission: true,
        },
        {
            name: 'Cadastros',
            path: '/treatment',
            codeScreen: EnumCodeScreen.CadastrosTratamento,
            viewPermission: true,
        }
        ]
    },

    {
        name: 'Relatórios',
        icon: 'fas fa-chart-bar',
        translate: 'sidebar.nav.REPORTS',
        submenu: [{
            name: 'Agendamentos',
            path: '/consultationappointments',
            codeScreen: EnumCodeScreen.CadastroConsultorio,
            viewPermission: false,
        },
        {
            viewPermission: false,
            codeScreen: EnumCodeScreen.Reports,
            name: 'Gráficos',
            path: '/reports'

        },
        {
            viewPermission: false,
            codeScreen: EnumCodeScreen.ClientesAusentes,
            name: 'Clientes Ausentes',
            path: '/absentClientReportPage'
        },
        {
            viewPermission: false,
            codeScreen: EnumCodeScreen.Aniversarios,
            name: 'Aniversários',
            path: '/birthReportPage'
        },
        {
            viewPermission: false,
            codeScreen: EnumCodeScreen.SurveyResults,
            name: 'Pesquisa de Satisfação',
            path: '/SurveyResults'
        }
        ]
    },

    {
        name: 'Configurações',
        icon: 'fa fa-cog',
        translate: 'sidebar.nav.CONFIGURACOES',
        submenu: [{
            name: 'Envio de Mensagens',
            path: '/configurationsregister',
            codeScreen: EnumCodeScreen.CadastroConfiguracoes,
            viewPermission: false,
        },
        {
            name: 'Pesquisa de Satisfação',
            path: '/SurveySettings',
            codeScreen: EnumCodeScreen.SurveySettings,
            viewPermission: true,
        },
        
        ]
    },

    {
        name: 'Suporte',
        icon: 'fa fa-question',
        translate: 'sidebar.nav.SUPORTE',
        submenu: [{
            name: 'Central de Atendimento',
            path: '/centralatendimento',
            codeScreen: EnumCodeScreen.CentralDeAtendimento,
            viewPermission: true,
        },
        {
            name: 'Perguntas Frequentes',
            path: '/perguntasfrequentes',
            codeScreen: EnumCodeScreen.PerguntasFrequentes,
            viewPermission: true,
        },
        ]
    },

    

    {
        name: 'Meu Plano',
        icon: 'fa fa-gift',
        translate: 'sidebar.nav.MEUPLANO',
        path: '/meuplano',
        codeScreen: EnumCodeScreen.MeuPlano,
        viewPermission: true,
        // submenu: [{
        //     name: 'Plano',
        //     path: '/',
        //     codeScreen: EnumCodeScreen.MeuPlano,
        //     viewPermission: false,
        // }
        // ]
    }






];

export default Menu;
